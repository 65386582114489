import numeral from 'numeral';

type Value = string | number | null | undefined;

export function Percent(number: Value, format = '0%') {
  return number !== null && number !== undefined ? numeral(Number(number) / 100).format(format) : '';
}

export function formatNumber(number: Value, format = '00') {
  return numeral(number).format(format);
}
